import * as React from 'react';
import { useState, useEffect, useRef, useContext } from "react";
import useFetch from "../../hooks/useFetch";
import { useHistory } from 'react-router-dom'
import { CurrentUserContext } from "../../contexts/currentUser";
import { Page } from '../page';
import { FormButton } from '../../components/buttons/button';
import { TitledBlock } from '../../components/blocks/titledBlock/titledBlock';
import {
  Row, NoticeText, FormBlock, FormItem, Title, TitleInput, ErrorMessage,
  SubmitButtonWrap, CancelButton, ImageWrap
} from './newBankAccount.styles';
import { useFormik, Field, FormikProvider } from 'formik';
import { iFieldStyleInit, iFieldStyleError, iFrameStyle, threeds } from './cardknoxOptions';
import IField, { CARD_TYPE, CVV_TYPE } from 'react-cardknox-ifields';
import {account, endPoint} from "../../config.json";
import {AlertMesssage} from "../../components/blocks/alertMessage/alertMessage";
import {FormikExpirationField, FormikPhoneField} from "../../components/formik/formik.styles";


const NewCCAccountPage = (props:any) => {

  const [iFieldStyle1, setIFieldStyle1] = useState<any>(iFieldStyleInit);
  const [iFieldStyle2, setIFieldStyle2] = useState<any>(iFieldStyleInit);
  const [iFrameIsLoaded, setIFrameIsLoaded] = useState<boolean>(false);
  const [iFieldTouched1, setIFieldTouched1] = useState<any>(false);
  const [iFieldTouched2, setIFieldTouched2] = useState<any>(false);
  const [iFieldError1, setIFieldError1] = useState<any>(null);
  const [iFieldError2, setIFieldError2] = useState<any>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const tokenRef = useRef('');
  const [{ responses, errors }, doFetch] = useFetch('addCCAccount');
  const history = useHistory();
  const [currentUserState] = useContext(CurrentUserContext);
  const inputReference = useRef(null);
  const achOptions = { autoSubmit: false, enableLogging: false, iFieldstyle: iFieldStyle1, iFrameStyle };
  const achOptions2 = { autoSubmit: false, enableLogging: false, iFieldstyle: iFieldStyle2, iFrameStyle };

  // Formik declaration & submission
  const formik = useFormik({
    initialValues: { nickname: '', name: '', exp: ''},
    validate,
    onSubmit: async (values) => {
      setIsSubmitting(true);
      await sleep(2000);
      const tokenData = tokenRef.current.split(";")
      tokenData[0] = tokenData[0].substring(tokenData[0].length - 4, tokenData[0].length);
      const data = { ...values, account: tokenData[0], token: tokenRef.current };
      if (!iFieldError1 && !iFieldError2)
        doFetch({ method: 'POST', data });
      else
        setIsSubmitting(false);
    }
  });

  useEffect(() => {
    if(!currentUserState.currentUser.settings) return;
    if (currentUserState.currentUser.settings.accept_cc != '1') {
      if (props.location.state && props.location.state.redirect)
        history.push(props.location.state.redirect);
      else
        history.push(`/payment`);
    }
  }, [currentUserState.currentUser])

  useEffect(() => {
    if (!currentUserState.isLoggedIn) return;
    formik.setFieldValue('name', `${currentUserState.currentUser.first_name} ${currentUserState.currentUser.last_name}`)
  }, [currentUserState.isLoggedIn])

  useEffect(() => {
    if (!responses) return;
    //console.log(responses);
    setIsSubmitting(false);
    if (props.location.state && props.location.state.redirect)
      history.push(props.location.state.redirect);
    else
      history.push(`/payment`);
  }, [responses])

  useEffect(() => {
    if (!errors) return;
    setIsSubmitting(false);
    //console.log(inputReference.current.getToken());
    inputReference.current.clearIfield();
    formik.setFieldValue('routing', '');
  }, [errors])

  // Validate iField
  useEffect(() => {
    setIFieldStyle1(iFieldTouched1 && iFieldError1 ? iFieldStyleError : iFieldStyleInit);
  }, [iFieldTouched1, iFieldError1]);
  useEffect(() => {
    setIFieldStyle2(iFieldTouched2 && iFieldError2 ? iFieldStyleError : iFieldStyleInit);
  }, [iFieldTouched2, iFieldError2]);

  // Validate on immidiate submit
  const validateIField = () => {
    if (!tokenRef.current) {
      setIFieldTouched1(true);
      setIFieldError1('Required');
    }
  }

  // Cardknox iFields user event methods
  const onLoad = () => {
    setIFrameIsLoaded(true);
  }

  const onCardUpdate = (data: any) => {
    console.log(data);
    if (data.isEmpty) setIFieldError1('Required');
    else if (!data.isValid) setIFieldError1('Must be a valid number');
    else setIFieldError1(null);
    if (data.event === 'blur') setIFieldTouched1(true);
  }
  const onCvvUpdate = (data: any) => {
    if (data.isEmpty) setIFieldError2('Required');
    //else if (!data.isValid) setIFieldError2('Must be a valid number');
    else setIFieldError2(null);
    if (data.event === 'blur') setIFieldTouched2(true);
  }

  const onToken = (data: any) => {
    tokenRef.current = data.xToken;
  }

  const onError = (data: any) => console.error("IFrame errored", data);

  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  return (
    <Page title='New Credit Card Account' tabNavigation={true}>

      <TitledBlock title='Add Credit Card'>
        <NoticeText>
          For your protection, new bank accounts might go through verification
          process before you can use them to make payments.
        </NoticeText>
      </TitledBlock>
      {errors && <AlertMesssage type={'error'} message={errors.message}></AlertMesssage>}
      <Row show={iFrameIsLoaded}>
        <FormikProvider value={formik}>

          <FormBlock onSubmit={formik.handleSubmit}>

            <FormItem>
              <Title>Card Nickname *</Title>
              <TitleInput id="nickname" type="text" error={formik.touched.nickname && formik.errors.nickname} {...formik.getFieldProps('nickname')} />
              <ErrorMessage>{formik.touched.nickname && formik.errors.nickname ? formik.errors.nickname : null}</ErrorMessage>
            </FormItem>

            <FormItem>
              <Title>Name On Card *</Title>
              <TitleInput id="name" type="text" error={formik.touched.name && formik.errors.name} {...formik.getFieldProps('name')} />
              <ErrorMessage>{formik.touched.name && formik.errors.name ? formik.errors.name : null}</ErrorMessage>
            </FormItem>

            <FormItem>
              <Title>Credit Card Number *</Title>
              <IField
                ref={inputReference}
                type={CARD_TYPE}
                account={account}
                options={achOptions}
                threeDS={threeds}
                src={endPoint}
                onLoad={onLoad}
                onUpdate={onCardUpdate}
                onToken={onToken}
                onError={onError}
              />

              {iFieldTouched1 && iFieldError1 && <ErrorMessage>{iFieldError1}</ErrorMessage>}
              <TitleInput type={'text'} value={tokenRef.current} hidden readOnly />
            </FormItem>

            <FormItem>
              <Field
                  name="exp"
                  label="Expiration (MM/YY) *"
                  component={FormikExpirationField}
              />
              <ErrorMessage>{formik.touched.exp && formik.errors.exp ? formik.errors.exp : null}</ErrorMessage>
            </FormItem>

            <FormItem>
              <Title>Security *</Title>
              <IField
                type={CVV_TYPE}
                account={account}
                options={achOptions2}
                threeDS={threeds}
                src={endPoint}
                onLoad={onLoad}
                onUpdate={onCvvUpdate}
                onToken={onToken}
                onError={onError}
              />

              {iFieldTouched2 && iFieldError2 && <ErrorMessage>{iFieldError2}</ErrorMessage>}
              <TitleInput type={'text'} value={tokenRef.current} hidden readOnly />
            </FormItem>



            <SubmitButtonWrap>
              <FormButton type="submit" onClick={validateIField} active={!isSubmitting} disabled={isSubmitting}>Add Credit Card</FormButton>
              <CancelButton type="button" onClick={() => history.push(!props.location.state || props.location.state.gohome ? '/' : props.location.state.redirect)}>Cancel</CancelButton>
            </SubmitButtonWrap>

          </FormBlock>


        </FormikProvider>
      </Row>

    </Page >
  );
}

const validate = (values: any) => {

  const errors: any = {};
  let exp = values.exp.replace(/_/g, '');
  if (!exp || exp =='/')
    errors.exp = 'Required';
  else {
    let date = exp.split('/');
    const last2 = Number(new Date().getFullYear().toString().slice(-2));
    if(date[0].length < 2 || date[0].length < 2 || date[0]*1 > 12 || date[0]*1 < 1 || date[1]*1 < last2 || date[1] < 1)
    errors.exp = 'Invalid expiration date';
  }
  /*
    if (!values.nickname) errors.nickname = 'Required';
    else if (values.nickname.length < 4) errors.nickname = 'Must be at leats 5 characters';

    if (!values.routing) errors.routing = 'Required';
    else if (values.routing.length !== 9) errors.routing = 'Must be 9 digits';
    else if (!/^\d+$/.test(values.routing)) errors.routing = 'Must contain only numbers'; */

  return errors;
};

export default NewCCAccountPage;